(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"index_atlas_1", frames: [[0,0,1920,1080]]},
		{name:"index_atlas_2", frames: [[0,0,1920,1080]]},
		{name:"index_atlas_3", frames: [[0,0,1920,1080]]},
		{name:"index_atlas_4", frames: [[1765,1142,45,78],[0,1082,500,201],[1153,1082,300,58],[502,1082,341,58],[1497,1142,137,58],[1754,1082,289,58],[1314,1142,181,58],[845,1082,306,58],[1055,1142,257,58],[781,1142,272,58],[1455,1082,297,58],[502,1142,277,58],[1636,1142,127,58],[0,0,1920,1080]]}
];


(lib.AnMovieClip = function(){
	this.actionFrames = [];
	this.ignorePause = false;
	this.gotoAndPlay = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
	}
	this.play = function(){
		cjs.MovieClip.prototype.play.call(this);
	}
	this.gotoAndStop = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
	}
	this.stop = function(){
		cjs.MovieClip.prototype.stop.call(this);
	}
}).prototype = p = new cjs.MovieClip();
// symbols:



(lib.CachedBmp_14 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_13 = function() {
	this.initialize(img.CachedBmp_13);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0,0,2204,112);


(lib.CachedBmp_12 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_11 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_10 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_9 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(4);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_8 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(5);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_7 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(6);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_6 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(7);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_5 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(8);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_4 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(9);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_3 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(10);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_2 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(11);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_1 = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(12);
}).prototype = p = new cjs.Sprite();



(lib.BG_5 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.BG_Claro = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.BG_Claro2 = function() {
	this.initialize(ss["index_atlas_3"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.BG_VerdeEscurol = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(13);
}).prototype = p = new cjs.Sprite();



(lib.Fundo_4 = function() {
	this.initialize(img.Fundo_4);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0,0,3840,2160);// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.Símbolo2 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// background
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#004245").beginStroke().moveTo(-583.8,511.6).lineTo(-583.8,-511.6).lineTo(583.8,-511.6).lineTo(583.8,511.6).closePath();
	this.shape.setTransform(959.7512,539.9978,1.644,1.0554);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Símbolo2, new cjs.Rectangle(0,0,1919.5,1080), null);


(lib.stripe = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// stripe
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#D6BC9C").beginStroke().moveTo(-41,45).lineTo(-41,-45).lineTo(41,-45).lineTo(41,45).closePath();
	this.shape.setTransform(0,0,1.0962,1);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-45,-45,90,90);


(lib.sign_out_text = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// SignOutText
	this.instance = new lib.CachedBmp_14();
	this.instance.setTransform(0,-0.9,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.sign_out_text, new cjs.Rectangle(0,-0.9,22.5,39), null);


(lib.sign_out_background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// SignOutBackground
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#720F11").beginStroke().moveTo(-20,30).lineTo(-20,-30).lineTo(20,-30).lineTo(20,30).closePath();
	this.shape.setTransform(20,30);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.sign_out_background, new cjs.Rectangle(0,0,40,60), null);


(lib.pseudo_after = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// MenuPseudoAfter
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(39,62,45,0.498)").beginStroke().moveTo(-78.5,30).lineTo(-78.5,-30).lineTo(78.6,-30).lineTo(78.6,30).closePath();
	this.shape.setTransform(78.55,30);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.pseudo_after, new cjs.Rectangle(0,0,157.1,60), null);


(lib.menu_line_solid = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// line
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#A26A0F").setStrokeStyle(2,0,1).moveTo(-960,0).lineTo(960,0);
	this.shape.setTransform(960,0);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_line_solid, new cjs.Rectangle(-1,-1,1922,2), null);


(lib.menu_line_gradient = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// line
	this.shape = new cjs.Shape();
	this.shape.graphics.beginLinearGradientFill(["#5BC3BE","#1F4F6E"],[0,1],-680,0,680,0).beginStroke().moveTo(-680,1).lineTo(-680,-1).lineTo(680,-1).lineTo(680,1).closePath();
	this.shape.setTransform(680,1);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_line_gradient, new cjs.Rectangle(0,0,1360,2), null);


(lib.menu_line_double = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// line
	this.instance = new lib.CachedBmp_13();
	this.instance.setTransform(-1,-1,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_line_double, new cjs.Rectangle(-1,-1,1102,56), null);


(lib.Interpolação11 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.instance = new lib.CachedBmp_12();
	this.instance.setTransform(-125.2,-51.05,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-125.2,-51,250,100.5);


(lib.botoes = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-236.1,78).lineTo(-236.1,-78).lineTo(236.1,-78).lineTo(236.1,78).closePath();
	this.shape.setTransform(236.05,78.025);
	this.shape._off = true;

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(3).to({_off:false},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,472.1,156.1);


(lib.background_effect = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_19 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).wait(19).call(this.frame_19).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,0,0);


(lib.background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,0,0);


(lib.arrow = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// arrow
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#D15627").beginStroke().moveTo(-24,12).lineTo(0,-12).lineTo(24,12).closePath();

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-12,48,24);


(lib.stripe_up = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {start:1,complete:11};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 *  Play the animate.
		 */
		this.gotoAndPlay('start');
	}
	this.frame_10 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const AllTapParent = window.parent.AllTap;
		
		
		AllTapParent.emit('animate_strip', {complente: true});
	}
	this.frame_20 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const AllTapParent = window.parent.AllTap;
		
		
		AllTapParent.emit('animate_strip', {end: true});
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(10).call(this.frame_10).wait(10).call(this.frame_20).wait(1));

	// stripe
	this.instance = new lib.stripe("synched",0);
	this.instance.setTransform(0,45);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({_off:false},0).to({y:-40},5).to({y:-35},2).to({y:-45},2).to({startPosition:0},1).to({y:-35},2).to({y:-40},2).to({y:45},5).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-45,45).lineTo(-45,-45).lineTo(45,-45).lineTo(45,45).closePath();
	this.shape.setTransform(0,45);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(21));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-45,-90,90,180);


(lib.menu_background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// menu_line_double
	this.instance = new lib.menu_line_double();
	this.instance.setTransform(550,-52,1,1,0,0,0,550,27);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// menu_line_solid
	this.instance_1 = new lib.menu_line_solid();
	this.instance_1.setTransform(960,-80,1,1,0,0,0,960,0);
	this.instance_1.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

	// menu_line_gradient
	this.instance_2 = new lib.menu_line_gradient();
	this.instance_2.setTransform(1240,-79,1,1,0,0,0,680,1);
	this.instance_2.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

	// background
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#3C5447").beginStroke().moveTo(-960,40).lineTo(-960,-40).lineTo(960,-40).lineTo(960,40).closePath();
	this.shape.setTransform(960,-40);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_background, new cjs.Rectangle(-1,-81,1922,81), null);


(lib.logo_small = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// logo_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-120,40).lineTo(-120,-40).lineTo(120,-40).lineTo(120,40).closePath();
	mask.setTransform(120,40);

	// logo
	this.instance = new lib.Interpolação11("synched",0);
	this.instance.setTransform(120.15,40.2,0.5965,0.5967);

	var maskedShapeInstanceList = [this.instance];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-120,40).lineTo(-120,-40).lineTo(120,-40).lineTo(120,40).closePath();
	this.shape.setTransform(120,40);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.logo_small, new cjs.Rectangle(0,0,240,80), null);


(lib.logo_large = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// logo_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-150,50).lineTo(-150,-50).lineTo(150,-50).lineTo(150,50).closePath();
	mask.setTransform(150,50);

	// logo
	this.instance = new lib.Interpolação11("synched",0);
	this.instance.setTransform(150.2,50.3,0.895,0.8951);

	var maskedShapeInstanceList = [this.instance];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-150,50).lineTo(-150,-50).lineTo(150,-50).lineTo(150,50).closePath();
	this.shape.setTransform(150,50);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.logo_large, new cjs.Rectangle(0,0,300,100), null);


(lib.background_dynamic = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {capa:0,after:1,localizacao:2,ficha:3,diferenciais:4,projetistas:5,implantacoes:6,plantas:7,perspectivas:8,personal:9,sobre:10};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(11));

	// background
	this.instance = new lib.BG_5();

	this.instance_1 = new lib.BG_Claro();
	this.instance_1.setTransform(1.25,1.15);

	this.instance_2 = new lib.Símbolo2();
	this.instance_2.alpha = 0.6484;

	this.instance_3 = new lib.Fundo_4();
	this.instance_3.setTransform(0,0,0.5,0.5);

	this.instance_4 = new lib.BG_Claro2();
	this.instance_4.setTransform(1.25,1.15);

	this.instance_5 = new lib.BG_VerdeEscurol();
	this.instance_5.setTransform(-0.75,-0.85,1.001,1.0011);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance}]}).to({state:[{t:this.instance}]},1).to({state:[{t:this.instance_1}]},1).to({state:[{t:this.instance_3},{t:this.instance_2}]},1).to({state:[{t:this.instance_4}]},1).to({state:[{t:this.instance_5}]},1).to({state:[{t:this.instance_3}]},1).to({state:[{t:this.instance_1}]},1).to({state:[{t:this.instance_3}]},1).to({state:[{t:this.instance_3},{t:this.instance_2}]},1).to({state:[{t:this.instance_1}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-0.7,-0.8,1922,1082);


(lib.arrow_up = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_9 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).wait(9).call(this.frame_9).wait(1));

	// arrow
	this.instance = new lib.arrow("synched",0);
	this.instance.setTransform(0,12);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({y:-26},5).to({y:-12},2).to({x:0.3,y:-18.4},2).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-38,48.3,62);


(lib.menu_marker = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {"capa":0,"after":1,"localizacao":2,"ficha":3,"diferenciais":4,"projetistas":5,"implantacoes":6,"plantas":7,"perspectivas":8,vistas:9,"personal":10,"sobre":11};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(12));

	// arrow_up
	this.instance = new lib.arrow_up();
	this.instance.setTransform(45,0);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).to({_off:true},1).wait(11));

	// stripe_up
	this.occ_animate = new lib.stripe_up();
	this.occ_animate.name = "occ_animate";
	this.occ_animate.setTransform(41.85,0,0.9274,1,0,0,0,0.1,0);
	this.occ_animate.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_animate).wait(1).to({visible:true},0).wait(1).to({scaleX:1.7608,x:375.85},0).wait(1).to({regX:0,scaleX:1.8737,x:560.6,y:13},0).wait(1).to({regX:0.1,scaleX:1.7346,x:744.5,y:0},0).wait(1).to({scaleX:1.6508,x:918.25,y:13},0).wait(1).to({scaleX:1.9246,x:1100.6},0).wait(1).to({scaleX:1.2267,x:1263.7,y:0},0).wait(1).to({scaleX:1.8287,x:1422.7},0).wait(1).to({regX:0.2,scaleX:0.9832,x:1570.65},0).wait(1).to({regX:0.1,scaleX:2.118,x:1731.6},0).wait(1).to({regX:0.2,scaleX:1.8902,x:190.35},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,1826.7,103);


// stage content:
(lib.index = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.actionFrames = [0];
	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const 
			AllTap = window.AllTap,
			current_name = 'index';
		
		/**
		 *
		 *  @param object
		 *  Set "this", object of canvas CreateJs.
		 */
		AllTap.setState({...AllTap.state, [`_this_${current_name}`]: this});
		
		/**
		 *
		 * @param array
		 *  [{
		 *      name: {string} = Name of occurrence not prefixed here "btn_".
		 *      occ_logo_large: {boolean} = Is visible (optional) !this is hide by default.
		 *      occ_logo_medium: {boolean} = Is visible (optional) !this is hide by default.
		 *      occ_logo_small: {boolean} = Is visible (optional) !this is hide by default.
		 *      occ_background: {boolean} = Is visible (optional) !this is hide by default.
		 *      occ_background_dynamic: {boolean} = Is visible (optional), go to label current !this is hide by default.
		 *      occ_background_effect: {boolean} = Is visible (optional), auto executable movie clip in frame 0 !this is hide by default.
		 *      occ_external: {boolean} = Is visible (optional), open in new page target="_blank".
		 *      occ_video: {boolean} = Is button video, run outside the iFrame (optional).
		 *      occ_tour: {boolean} = Is button tour, run outside the iFrame (optional).
		 *            src: URL of external and video or tour.
		 *      page_default: {boolean} = Define function $page_default() "root", finish of video (optional).
		 *      page_after: {boolean} = Define function $page_after().
		 *  }],
		 *  before: Name of "page_default", occurence menu_marker is hide.
		 *  after: {boolean} = Page before in "page_default size height: 100%" page after in "page_after" size default.
		 */
		AllTap.createMenu([
		    {
		        name: 'capa',
		        page_default: true,
				occ_background_dynamic: true
		    },
		    {
		        name: 'after',
		        page_after: true,
				occ_background_dynamic: true
		    },
		    {
		        name: 'sobre',
				occ_background_dynamic: true
		    },
		    {
		        name: 'localizacao',
				occ_background_dynamic: true
		    },
		    {
		        name: 'ficha',
				occ_background_dynamic: true
		    },
			{
		        name: 'diferenciais',
				occ_background_dynamic: true
		    },
			{
		        name: 'projetistas',
				occ_background_dynamic: true
		    },
			{
		        name: 'implantacoes',
				occ_background_dynamic: true
		    },
		    {
		        name: 'plantas',
				occ_background_dynamic: true
		    },
		    {
		        name: 'perspectivas',
				occ_background_dynamic: true
		    },
		    {
		        name: 'vistas',
				occ_background_dynamic: true
		    },
		    {
		        name: 'personal',
				occ_background_dynamic: true
		    }
		], 'capa', true);
		
		/*
		function prevMain() {
			window.open(`${window.location.protocol}//${window.location.host}`, "_self");
		}
		
		this.btn_sign_out.addEventListener("click", prevMain);
		*/
		/*this.btSair.addEventListener("click", fl_sair);
		
		function fl_sair() {
			window.open("./../../index.html", "_self");
		}*/
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// BackgroundEffect
	this.occ_background_effect = new lib.background_effect();
	this.occ_background_effect.name = "occ_background_effect";
	this.occ_background_effect.setTransform(8.2,0.25);

	this.timeline.addTween(cjs.Tween.get(this.occ_background_effect).wait(1));

	// LogoLarge
	this.occ_logo_large = new lib.logo_large();
	this.occ_logo_large.name = "occ_logo_large";
	this.occ_logo_large.setTransform(0,980.2,1,1.0001,0,0,0,0,0.2);
	this.occ_logo_large.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_logo_large).wait(1));

	// LogoSmall
	this.occ_logo_small = new lib.logo_small();
	this.occ_logo_small.name = "occ_logo_small";
	this.occ_logo_small.setTransform(30.1,1000.1,1.0001,0.9998,0,0,0,0.1,0.1);
	this.occ_logo_small.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_logo_small).wait(1));

	// SignOutButton
	this.btn_sign_out = new lib.botoes();
	this.btn_sign_out.name = "btn_sign_out";
	this.btn_sign_out.setTransform(1870,1000,0.1059,0.5127);
	this.btn_sign_out.visible = false;
	new cjs.ButtonHelper(this.btn_sign_out, 0, 1, 2, false, new lib.botoes(), 3);

	this.timeline.addTween(cjs.Tween.get(this.btn_sign_out).wait(1));

	// sign_out_text
	this.instance = new lib.sign_out_text();
	this.instance.setTransform(1888.85,1021.3);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// sign_out_background
	this.instance_1 = new lib.sign_out_background();
	this.instance_1.setTransform(1880,1010);
	this.instance_1.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

	// MenuButton
	this.btn_sobre = new lib.botoes();
	this.btn_sobre.name = "btn_sobre";
	this.btn_sobre.setTransform(151.75,1010,0.3605,0.3846,0,0,0,0.7,0);
	new cjs.ButtonHelper(this.btn_sobre, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_personal = new lib.botoes();
	this.btn_personal.name = "btn_personal";
	this.btn_personal.setTransform(1683,1010,0.4037,0.3846,0,0,0,0.8,0);
	new cjs.ButtonHelper(this.btn_personal, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_vistas = new lib.botoes();
	this.btn_vistas.name = "btn_vistas";
	this.btn_vistas.setTransform(1572.95,1010,0.1874,0.3846,0,0,0,0.8,0);
	new cjs.ButtonHelper(this.btn_vistas, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_projetistas = new lib.botoes();
	this.btn_projetistas.name = "btn_projetistas";
	this.btn_projetistas.setTransform(890.7,1010,0.3148,0.3846,0,0,0,1,0);
	new cjs.ButtonHelper(this.btn_projetistas, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_implantacoes = new lib.botoes();
	this.btn_implantacoes.name = "btn_implantacoes";
	this.btn_implantacoes.setTransform(1060.7,1010,0.3668,0.3846,0,0,0,0.8,0);
	new cjs.ButtonHelper(this.btn_implantacoes, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_ficha = new lib.botoes();
	this.btn_ficha.name = "btn_ficha";
	this.btn_ficha.setTransform(523.15,1010,0.3571,0.3846,0,0,0,0.7,0);
	new cjs.ButtonHelper(this.btn_ficha, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_perspectivas = new lib.botoes();
	this.btn_perspectivas.name = "btn_perspectivas";
	this.btn_perspectivas.setTransform(1387.1,1010,0.3486,0.3846,0,0,0,0.8,0);
	new cjs.ButtonHelper(this.btn_perspectivas, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_plantas = new lib.botoes();
	this.btn_plantas.name = "btn_plantas";
	this.btn_plantas.setTransform(1255.25,1010,0.2338,0.3846,0,0,0,1.1,0);
	new cjs.ButtonHelper(this.btn_plantas, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_localizacao = new lib.botoes();
	this.btn_localizacao.name = "btn_localizacao";
	this.btn_localizacao.setTransform(343.3,1010,0.3357,0.3846,0,0,0,0.8,0);
	new cjs.ButtonHelper(this.btn_localizacao, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_capa = new lib.botoes();
	this.btn_capa.name = "btn_capa";
	this.btn_capa.setTransform(46.9,1010,0.1769,0.3843,0,0,0,1.7,0);
	new cjs.ButtonHelper(this.btn_capa, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_diferenciais = new lib.botoes();
	this.btn_diferenciais.name = "btn_diferenciais";
	this.btn_diferenciais.setTransform(713.2,1010.05,0.3306,0.3846,0,0,0,0.9,0.1);
	new cjs.ButtonHelper(this.btn_diferenciais, 0, 1, 2, false, new lib.botoes(), 3);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.btn_diferenciais},{t:this.btn_capa},{t:this.btn_localizacao},{t:this.btn_plantas},{t:this.btn_perspectivas},{t:this.btn_ficha},{t:this.btn_implantacoes},{t:this.btn_projetistas},{t:this.btn_vistas},{t:this.btn_personal},{t:this.btn_sobre}]}).wait(1));

	// MenuPseudoAfter
	this.instance_2 = new lib.pseudo_after();
	this.instance_2.setTransform(272.65,1113.6,1.103,0.6666,0,0,0,0.4,0.1);

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

	// MenuTitle
	this.instance_3 = new lib.CachedBmp_11();
	this.instance_3.setTransform(161.5,1025.6,0.5,0.5);

	this.instance_4 = new lib.CachedBmp_10();
	this.instance_4.setTransform(1692.8,1025.6,0.5,0.5);

	this.instance_5 = new lib.CachedBmp_9();
	this.instance_5.setTransform(1582.9,1025.6,0.5,0.5);

	this.instance_6 = new lib.CachedBmp_8();
	this.instance_6.setTransform(1396.9,1025.6,0.5,0.5);

	this.instance_7 = new lib.CachedBmp_7();
	this.instance_7.setTransform(1265.1,1025.6,0.5,0.5);

	this.instance_8 = new lib.CachedBmp_6();
	this.instance_8.setTransform(1070.5,1025.6,0.5,0.5);

	this.instance_9 = new lib.CachedBmp_5();
	this.instance_9.setTransform(900.5,1025.6,0.5,0.5);

	this.instance_10 = new lib.CachedBmp_4();
	this.instance_10.setTransform(723,1025.6,0.5,0.5);

	this.instance_11 = new lib.CachedBmp_3();
	this.instance_11.setTransform(533,1025.6,0.5,0.5);

	this.instance_12 = new lib.CachedBmp_2();
	this.instance_12.setTransform(353.1,1025.6,0.5,0.5);

	this.instance_13 = new lib.CachedBmp_1();
	this.instance_13.setTransform(56.6,1025.6,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3}]}).wait(1));

	// MenuMarker
	this.occ_menu_marker = new lib.menu_marker();
	this.occ_menu_marker.name = "occ_menu_marker";
	this.occ_menu_marker.setTransform(46.6,1080);
	this.occ_menu_marker.shadow = new cjs.Shadow("rgba(0,0,0,1)",0,-1,3);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_marker).wait(1));

	// MenuBackground
	this.occ_menu_background = new lib.menu_background();
	this.occ_menu_background.name = "occ_menu_background";
	this.occ_menu_background.setTransform(0,1080);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_background).wait(1));

	// background
	this.occ_background = new lib.background();
	this.occ_background.name = "occ_background";
	this.occ_background.setTransform(960,500,1,1,0,0,0,960,500);
	this.occ_background.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_background).wait(1));

	// BackgroundDynamic
	this.occ_background_dynamic = new lib.background_dynamic();
	this.occ_background_dynamic.name = "occ_background_dynamic";

	this.timeline.addTween(cjs.Tween.get(this.occ_background_dynamic).wait(1));

	// stageBackground
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("rgba(0,0,0,0)").setStrokeStyle(1,1,1,3,true).moveTo(-970,-550).lineTo(970,-550).lineTo(970,550).lineTo(-970,550).closePath();
	this.shape.setTransform(960,540);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("#769368").beginStroke().moveTo(-970,550).lineTo(-970,-550).lineTo(970,-550).lineTo(970,550).closePath();
	this.shape_1.setTransform(960,540);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new lib.AnMovieClip();
p.nominalBounds = new cjs.Rectangle(949,529,982,648);
// library properties:
lib.properties = {
	id: '7C9EF5D5AF5D5F46AFF0D236F6A9A88F',
	width: 1920,
	height: 1080,
	fps: 30,
	color: "#769368",
	opacity: 1.00,
	manifest: [
		{src:"./images/CachedBmp_13.png", id:"CachedBmp_13"},
		{src:"./images/Fundo_4.png", id:"Fundo_4"},
		{src:"./images/index_atlas_1.png", id:"index_atlas_1"},
		{src:"./images/index_atlas_2.png", id:"index_atlas_2"},
		{src:"./images/index_atlas_3.png", id:"index_atlas_3"},
		{src:"./images/index_atlas_4.png", id:"index_atlas_4"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['7C9EF5D5AF5D5F46AFF0D236F6A9A88F'] = {
	getStage: function() { return exportRoot.stage; },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}


an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
	var lastW, lastH, lastS=1;		
	window.addEventListener('resize', resizeCanvas);		
	resizeCanvas();		
	function resizeCanvas() {			
		var w = lib.properties.width, h = lib.properties.height;			
		var iw = window.innerWidth, ih=window.innerHeight;			
		var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
		if(isResp) {                
			if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
				sRatio = lastS;                
			}				
			else if(!isScale) {					
				if(iw<w || ih<h)						
					sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==1) {					
				sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==2) {					
				sRatio = Math.max(xRatio, yRatio);				
			}			
		}
		domContainers[0].width = w * pRatio * sRatio;			
		domContainers[0].height = h * pRatio * sRatio;
		domContainers.forEach(function(container) {				
			container.style.width = w * sRatio + 'px';				
			container.style.height = h * sRatio + 'px';			
		});
		stage.scaleX = pRatio*sRatio;			
		stage.scaleY = pRatio*sRatio;
		lastW = iw; lastH = ih; lastS = sRatio;            
		stage.tickOnUpdate = false;            
		stage.update();            
		stage.tickOnUpdate = true;		
	}
}
an.handleSoundStreamOnTick = function(event) {
	if(!event.paused){
		var stageChild = stage.getChildAt(0);
		if(!stageChild.paused || stageChild.ignorePause){
			stageChild.syncStreamSounds();
		}
	}
}
an.handleFilterCache = function(event) {
	if(!event.paused){
		var target = event.target;
		if(target){
			if(target.filterCacheList){
				for(var index = 0; index < target.filterCacheList.length ; index++){
					var cacheInst = target.filterCacheList[index];
					if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
						cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
					}
				}
			}
		}
	}
}


})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
